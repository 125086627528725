export function ajaxifyForm(ID: string) {
  let form = document.getElementById(ID) as HTMLFormElement;
  console.log("ajaxifyForm: " + ID);
  form.addEventListener("submit", async function(event) {
    event.preventDefault();

    const form = event.target as HTMLFormElement;

    fetch(form.action, {
      method: form.method,
      body: new URLSearchParams([ ...(new FormData(form)) as any ]),
    })
      .then(function(response: Response) {
        return response.json();
      })
      .then(function(json) {
        window.dispatchEvent(new CustomEvent('formSent'));
        if (typeof json === "string") json = JSON.parse(json);
        let parent = document.getElementById(form.dataset.parent || "");
        // @ts-ignore
        let result = parent.getElementsByClassName("result")[0];
        result.classList.add(json.success ? "success" : "error");
        result.classList.remove("hidden");
        // @ts-ignore
        let resultText = parent.getElementsByClassName("result_text")[0];
        // @ts-ignore
        if (resultText && json.msg) resultText.innerHTML(json.msg);
        // @ts-ignore
        parent.getElementsByClassName("subscribe")[0].classList.add("hidden");
      })
      .catch((error) => console.log(error));
  });
}
